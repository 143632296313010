import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from '../components/image'
import Text from '../styles/text'
import PageStyled from '../styles/page'
import DocsStyled from '../styles/docs'
import Folio from '../styles/portfolio'

const EdsPage = (props) => {
  return (
    <Layout path={props.path}>
      <SEO title="Home" />
      <PageStyled bg={['#e1f7ff', '#f1fbff']}>
        <PageStyled.Content>
          <Text.Hero>PosterMyWall <strong>Design, Brand and UX</strong></Text.Hero>
          <DocsStyled>
            <Image name='pmwpic' width={700} />
          </DocsStyled>
        </PageStyled.Content> 
      </PageStyled>
      <PageStyled.Content2>
        <Folio>
          <Folio.Overview>
            <strong>Overview</strong>
            <div>After joining postermywall it was imperative for me to take up the lead role in developing a strong design foundation for the company given that I
              was the first product designer. The obvious start, rooting from my experience was to work on a design system so that all my visual design was scalable
              for the team to build in the future. An additional challenge that I hit during my work was not only standardizing a system for the UI of the website but also
              a branding system to maintain the cohesive brand look. Since the company is currently in a phase of transitioning from the old design to a newer design, It
              had become essential for me to guage the direction of where we wanted to head and where our users want to see us in the future through a series of surveys, interview, A/B tests 
              and experimentation within the product.</div>
              <br />
              <div>Due to the NDA signed with the company, I cannot show much of the work that I have been doing and the results that we have been driven from our research, but I am more than willing to discuss that in the interview.</div>
          </Folio.Overview>
          <Folio.Role>
            <strong>Company</strong>
            <a href='https://www.postermywall.com/' target='_blank'>PosterMyWall</a>
            <strong>Role</strong>
            Product Designer
            <strong>Timeline</strong>
            April 2020 - Present
          </Folio.Role>
        </Folio>
      </PageStyled.Content2>
      <PageStyled>
        <PageStyled.Content2>
          <Folio>
            <Folio.Description>
              <Text.Hero left><strong>Preview</strong></Text.Hero>
              <Image name='pmwdsys' width={700} />
            </Folio.Description>
          </Folio>
        </PageStyled.Content2>
      </PageStyled>
      {/* <PageStyled.Content2>
        <Folio>
          <Folio.Description>
            <Text.Hero left><strong>Problem</strong></Text.Hero>
          </Folio.Description>
        </Folio>
        <Folio>
          <Folio.Description>
            <Text.Hero left><strong>Solution</strong></Text.Hero>
          </Folio.Description>
        </Folio>
        <Folio>
          <Folio.Description>
            <Text.Hero left><strong>Challenges</strong></Text.Hero>
          </Folio.Description>
        </Folio>
        <Folio>
          <Folio.Description>
            <Text.Hero left><strong>Key Learnings</strong></Text.Hero>
          </Folio.Description>
        </Folio>
      </PageStyled.Content2> */}
    </Layout>
  )
}

export default EdsPage
